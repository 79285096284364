<template>
  <div>
    <h2 class="text-center my-4">Competitors Analyzer</h2>

    <b-container fluid class="shadow mb-5">
      <b-row class="mb-5">
        <b-col class="d-flex align-items-center">
          <span>Mode: </span>
          <b-form-group>
            <b-form-radio-group
              id="mode"
              buttons
              v-model="mode"
              :options="['URL', 'SERP']"
              size="sm"
              name="mode-select"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-5">
        <b-col class="mb-1">
          <b-form-input
            class="mb-3"
            id="keyword-string"
            style="box-shadow: none"
            v-model="keyword.text"
            placeholder="Enter keyword ..."
            size="sm"
            squared
            trim
            description="keyword"
            autofocus
            type="text"
            @keydown.enter.native="getCompetitors()"
          ></b-form-input>
          <b-form-input
            class="mb-3"
            id="url-string"
            style="box-shadow: none"
            v-model="keyword.url"
            placeholder="Enter url ..."
            size="sm"
            squared
            trim
            description="url"
            autofocus
            type="text"
            @keydown.enter.native="getCompetitors()"
          ></b-form-input>

          <b-form-textarea
            v-if="mode == 'URL'"
            class="mb-3"
            style="box-shadow: none"
            id="urls"
            v-model="urlsString"
            placeholder="Enter Competitors URLs (New line separated) ..."
            rows="10"
            max-rows="50"
            size="sm"
            squared
            trim
            description="URLs to analyze"
            title="Enter URLs (New line separated)"
            :state="urlsString.length > 5"
            no-auto-shrink
          ></b-form-textarea>

          <b-button-group>
            <b-dropdown
              variant="dark"
              size="sm"
              squared
              :text="
                'location: ' +
                keyword.location.name +
                ' ' +
                '(' +
                keyword.location.code +
                ')'
              "
              id="keyword-location"
              name="keyword.location"
              v-model="keyword.location"
            >
              <b-dropdown-item-button
                v-for="location in locations"
                :key="location.name"
                :value="location.country_iso_code"
                squared
                @click="keyword.location = location"
              >
                {{
                  location.name + " " + "(" + location.country_iso_code + ")"
                }}
              </b-dropdown-item-button>
            </b-dropdown>

            <b-dropdown
              variant="dark"
              size="sm"
              squared
              v-model="keyword.language_code"
              :text="'language: ' + keyword.language_code"
            >
              <b-dropdown-item-button
                v-for="language_code in languages"
                :key="language_code"
                :value="language_code"
                squared
                @click="keyword.language_code = language_code"
              >
                {{ language_code }}
              </b-dropdown-item-button>
            </b-dropdown>

            <b-dropdown
              variant="dark"
              size="sm"
              squared
              v-model="keyword.device"
              :text="'device: ' + keyword.device"
            >
              <b-dropdown-item-button
                v-for="device in devices"
                :key="device"
                :value="device"
                squared
                @click="keyword.device = device"
              >
                {{ device }}
              </b-dropdown-item-button>
            </b-dropdown>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row class="pb-3">
        <b-col cols="2">
          <b-form-checkbox
            id="checkbox-exclude-own-url"
            v-model="keyword.excludeOwnUrl"
            name="checkbox-exclude-own-url"
            value="accepted"
            unchecked-value="not_accepted"
            squared
          >
            Exclude Own Url
          </b-form-checkbox>
        </b-col>
        <b-col>
          <FilterInput :filterInput="filterInput" />
        </b-col>
      </b-row>
      <b-row
        ><b-button
          :pressed="false"
          style="box-shadow: none"
          active-class="active"
          size="sm"
          squared
          variant="danger"
          @click="getCompetitors()"
          >Analyze</b-button
        ></b-row
      >
      <h5 class="text-center my-4" style="min-height: 1.2em">Results for</h5>
      <h5 class="text-center my-4" style="min-height: 1.2em">
        <a
          :href="'https://www.google.com/search?q=' + keyword.text"
          class="text-decoration-none text-muted"
          >Keyword: {{ keyword.text }}</a
        >
      </h5>
      <h5 class="text-center my-4" style="min-height: 1.2em">
        <a :href="keyword.url" class="text-decoration-none text-muted"
          >URL: {{ keyword.url }}</a
        >
      </h5>

      <!-- spinner -->
      <div class="text-center mb-5" v-if="isBusy && spinner">
        <b-row type="grow" class="justify-content-md-center my-5 pb-5">
          <b-spinner
            variant="dark"
            style="width: 25rem; height: 25rem"
          ></b-spinner>
        </b-row>
        <span class="sr-only">Analyzing "{{ keyword.url }}"</span>
      </div>
    </b-container>

    <b-container
      fluid
      v-if="!isBusy && this.dataforseoItems.length > 0"
      class="shadow"
    >
      <b-row class="mb-5 shadow">
        <b-col>
          <h2 class="text-center m-3">Competitors</h2>
          <table class="table striped small hover responsive">
            <thead>
              <tr>
                <th
                  style="height: 1.2em; padding: 0; border-top: 0"
                  scope="col"
                >
                  Position
                </th>
                <th
                  style="height: 1.2em; padding: 0; border-top: 0"
                  scope="col"
                >
                  Title
                </th>
                <th
                  style="height: 1.2em; padding: 0; border-top: 0"
                  scope="col"
                >
                  URL
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataforseoItems" :key="item.id">
                <td style="height: 1.2em; padding: 0; border-top: 0">
                  {{ item.rank_group }}
                </td>
                <td style="height: 1.2em; padding: 0; border-top: 0">
                  {{ item.title }}
                </td>
                <td style="height: 1.2em; padding: 0; border-top: 0">
                  <a :href="item.url">{{ item.url }}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>

      <b-row v-if="!isBusy" class="mb-5 shadow">
        <h2 class="text-center my-4">Taxonomy</h2>
        <b-col cols="6" class="shadow mt-5">
          <Chart
            :chartOptions="chartMissingConceptsOptions"
            :series="seriesMissingConcepts"
            :type.sync="chartMissingConceptsOptions.chart.type"
            id="chart-missing-concepts"
            fluid
          />
        </b-col>
        <b-col cols="6" class="shadow mt-5">
          <Chart
            :chartOptions="chartMissingKeywordsOptions"
            :series="seriesMissingKeywords"
            :type.sync="chartMissingKeywordsOptions.chart.type"
            id="chart-missing-keywords"
            fluid
          />
        </b-col>
        <b-col cols="6" class="shadow mt-5">
          <Chart
            :chartOptions="chartMissingCategoriesOptions"
            :series="seriesMissingCategories"
            :type.sync="chartMissingCategoriesOptions.chart.type"
            id="chart-missing-categories"
            fluid
          />
        </b-col>
        <b-col cols="6" class="shadow mt-5">
          <Chart
            :chartOptions="chartMissingEntitiesOptions"
            :series="seriesMissingEntities"
            :type.sync="chartMissingEntitiesOptions.chart.type"
            id="chart-missing-entities"
            fluid
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { CompetitorsService } from "../services/competitors/competitors.service";
import FilterInput from "../components/FilterInput.vue";
import Chart from "../components/Chart.vue";

export default {
  name: "Taxonomy",
  components: {
    FilterInput,
    Chart,
  },
  data() {
    return {
      isBusy: true,
      spinner: false,
      mode: "URL",

      competitorsColumns: [],
      sort: {
        sortBy: "id",
        sortDesc: false,
      },

      dataFrom: "",
      keyword: {
        text: "project",
        url: "https://blog.logrocket.com/setting-up-first-gutenberg-project/",
        language_code: "de",
        device: "mobile",
        // location: ["2276", "Germany", "", "DE", "Country"],
        location: {
          code: 2276,
          name: "Germany",
          code_parent: "",
          country_iso_code: "DE",
          type: "Country",
        },

        forceUpdate: false,
        excludeOwnUrl: false,
      },

      urlsString:
        "https://www.dipat.de/aktuelles/bundesgerichtshof-patientenverfuegung-2018\nhttps://www.npmjs.com/package/sitemapper",

      languages: [
        "en",
        "de",
        "ru",
        // "fa",
        // "nl",
        // "fr",
        // "id",
        // "it",
        // // 'ja',
        // "no",
        // "pt",
        // "es",
        // "sv",
      ],
      devices: ["desktop", "mobile"],
      locations: [],

      filterInput: {
        value: 5,
        min: 1,
        max: 20,
        step: 1,
        name: "Google top results",
      },

      //dataforseo
      dataforseoItems: [],

      // Missing concepts
      seriesMissingConcepts: [],
      chartMissingConceptsOptions: {
        chart: {
          id: "Concepts",
          type: "bar",
          stacked: true,
          //   height: 1440,
          // stackType: "100%",
          // stackType: "normal",
        },
        // colors: ["#0000FF", "#FF0000"],
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%",
            // borderRadius: 4,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },

        grid: {
          xaxis: {
            lines: {
              show: false,
              //   show: true,
            },
          },
        },

        // title: {
        //   text: "Concepts",
        // },
      },

      // Missing keywords
      seriesMissingKeywords: [],
      chartMissingKeywordsOptions: {
        chart: {
          id: "Keywords",
          type: "bar",
          height: "auto",
          toolbar: {
            autoSelected: "pan",
            show: true,
            export: {
              csv: {
                headerCategory: "keyword",
                headerValue: "value",
              },
            },
          },
        },
        plotOptions: {},
        dataLabels: {},
        xaxis: {},
      },

      // Missing categories
      seriesMissingCategories: [],
      chartMissingCategoriesOptions: {
        chart: {
          id: "Categories",
          type: "bar",
          height: "auto",
          toolbar: {
            autoSelected: "pan",
            show: true,
            export: {
              csv: {
                headerCategory: "category",
                headerValue: "value",
              },
            },
          },
        },
        plotOptions: {},
        dataLabels: {},
        xaxis: {},
      },

      // Missing entities
      seriesMissingEntities: [],
      chartMissingEntitiesOptions: {
        chart: {
          id: "Entities",
          type: "bar",
          height: "auto",
          toolbar: {
            autoSelected: "pan",
            show: true,
            export: {
              csv: {
                headerCategory: "entity",
                headerValue: "value",
              },
            },
          },
        },
        plotOptions: {},
        dataLabels: {},
        xaxis: {},
      },

      missingConcepts: [],
      missingCategories: [],
      missingEntities: [],
      missingKeywords: [],
    };
  },

  async beforeMount() {
    this.isBusy = true;
    this.spinner = true;
    const locations = await CompetitorsService.getLocations();
    this.locations = locations.data;
    this.isBusy = false;
    this.spinner = false;
    return;
  },

  methods: {
    async getCompetitors() {
      if (
        this.keyword.text
        // && this.keyword.url
      ) {
        this.isBusy = true;
        this.spinner = true;

        const urls = this.urlsString.split("\n").reduce((newArr, url) => {
          if (this.mode == "URL") {
            newArr.push(url.trim());
          }
          return newArr;
        }, []);

        const { data: data } = await CompetitorsService.getCompetitors({
          keyword: this.keyword.text.toLowerCase(),
          url: this.keyword.url.toLowerCase(),
          urls: urls,
          language_code: this.keyword.language_code,
          location_code: this.keyword.location.code.toString(),
          device: this.keyword.device,
          serp_top_n: this.filterInput.value,
          // serp_top_n: 2,
          forceUpdate: this.keyword.forceUpdate,
          excludeOwnUrl: this.keyword.excludeOwnUrl,
        });

        this.dataforseoItems = data.competitorsData;
        this.setChartMissingConceptsData(data.competitorsData, data.urlData);
        this.setChartMissingKeywordsData(data.competitorsData, data.urlData);
        this.setChartMissingCategoriesData(data.competitorsData, data.urlData);
        this.setChartMissingEntitiesData(data.competitorsData, data.urlData);
        this.keyword.forceUpdate = false;
        this.isBusy = false;
        this.spinner = false;
        return;
      }
    },

    setChartMissingEntitiesData(competitorsData, urlData) {
      const competitorsEntitiesAll = [];
      competitorsData.forEach((competitorData) => {
        const competitorEntities = (({ entities }) => ({ entities }))(
          competitorData
        ).entities;
        competitorEntities.forEach((entity) => {
          competitorsEntitiesAll.push(entity);
        });
      });

      const competitorsEntitiesFiltered = [];
      competitorsEntitiesAll.forEach((entity) => {
        const entityData = {
          entity: entity.text,
          type: entity.type,
          relevance_sum: entity.relevance,
          count: +entity.count,
          serps: [entity.url],
        };
        competitorsEntitiesAll.forEach((entity2) => {
          if (entity.text == entity2.text && entity.url != entity2.url) {
            entityData.relevance_sum += +entity2.relevance;
            // entityData.count++;
            entityData.count += +entity2.count;
            entityData.serps.push(entity2.url);
          }
        });
        const data = {
          entity: entityData.entity,
          type: entityData.type,
          count: entityData.count,
          relevance_avg: Number(
            +entityData.relevance_sum / entityData.serps.length
          ).toFixed(3),
          serps: entityData.serps,
        };
        competitorsEntitiesFiltered.push(data);
      });

      // console.log(competitorsEntitiesFiltered, 332211);

      const competitorsEntities = competitorsEntitiesFiltered
        .filter(
          (v, i, a) =>
            a.findIndex((t) =>
              ["entity", "type"].every((k) => t[k] === v[k])
            ) === i
        )
        .sort((a, b) =>
          a.relevance_sum_avg < b.relevance_sum_avg
            ? 1
            : b.relevance_sum_avg < a.relevance_sum_avg
            ? -1
            : 0
        )
        .sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0));

      const chartOptions = [];
      const counts = [];
      // const count_sums = [];
      // const count_sum_avgs = [];
      // const relevance_sum_avgs = [];

      // for (let i = 0; i < entities.slice(0, 20).length; i++) {
      //   const item = entities[i];
      //   // counts.push(item.count);
      //   count_sums.push(item.count_sum);
      //   count_sum_avgs.push(item.count_sum_avg);
      //   relevance_sum_avgs.push(item.relevance_sum_avg);
      // }

      const relevance_avgs = [];
      for (let i = 0; i < competitorsEntities.slice(0, 20).length; i++) {
        const item = competitorsEntities[i];
        chartOptions.push(item.entity + " | " + item.type);
        if (urlData.entities.some((e) => e.text === item.entity)) {
          counts.push({
            x: item.entity,
            y: item.count,
            // fillColor: "#5555FF",
          });
          relevance_avgs.push({
            x: item.entity,
            y: item.relevance_avg,
            // fillColor: "#0000FF",
          });
        } else {
          counts.push({
            x: item.entity,
            y: item.count,
            fillColor: "#FF5555",
          });
          relevance_avgs.push({
            x: item.entity,
            y: item.relevance_avg,
            fillColor: "#FF0000",
          });
        }
      }
      this.chartMissingEntitiesOptions = {
        ...this.chartMissingEntitiesOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                // fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              // fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesMissingEntities = [
        { name: "Count", data: counts },
        // { name: "count_sum", data: count_sums },
        // { name: "count_sum_avg", data: count_sum_avgs },
        { name: "Relevance avg", data: relevance_avgs },
      ];
    },

    setChartMissingCategoriesData(competitorsData, urlData) {
      const competitorsCategoriesAll = [];
      competitorsData.forEach((competitorData) => {
        const competitorCategories = (({ categories }) => ({ categories }))(
          competitorData
        ).categories;
        competitorCategories.forEach((category) => {
          competitorsCategoriesAll.push(category);
        });
      });
      const competitorsCategoriesFiltered = [];

      competitorsCategoriesAll.forEach((category) => {
        const categoryData = {
          category: category.label,
          count: 1,
          score_sum: category.score,
          serps: [category.url],
        };

        competitorsCategoriesAll.forEach((category2) => {
          if (
            category.label == category2.label &&
            category.url != category2.url
          ) {
            categoryData.score_sum += +category2.score;
            categoryData.count++;
            categoryData.serps.push(category2.url);
          }
        });
        const data = {
          category: categoryData.category,
          count: categoryData.count,
          score_avg: Number(
            +categoryData.score_sum / +categoryData.count
          ).toFixed(3),
          serps: categoryData.serps,
        };
        competitorsCategoriesFiltered.push(data);
      });

      const competitorsCategories = competitorsCategoriesFiltered
        .filter(
          (v, i, a) =>
            a.findIndex((t) =>
              ["category", "score_avg"].every((k) => t[k] === v[k])
            ) === i
        )
        .sort((a, b) =>
          a.category < b.category ? 1 : b.category < a.category ? -1 : 0
        )
        .sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0));

      const chartOptions = [];
      const score_avgs = [];
      const counts = [];
      for (let i = 0; i < competitorsCategories.slice(0, 100).length; i++) {
        const item = competitorsCategories[i];
        chartOptions.push(item.category);
        if (urlData.categories.some((e) => e.label === item.category)) {
          score_avgs.push({
            x: item.category,
            y: item.score_avg,
            // fillColor: "#5555FF",
          });
          counts.push({
            x: item.category,
            y: item.count,
            // fillColor: "#5555FF",
          });
        } else {
          score_avgs.push({
            x: item.category,
            y: item.score_avg,
            fillColor: "#FF5555",
          });
          counts.push({
            x: item.category,
            y: item.count,
            fillColor: "#FF5555",
          });
        }
      }
      this.chartMissingCategoriesOptions = {
        ...this.chartMissingCategoriesOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "20%",
              borderRadius: 4,
              horizontal: true,
              // columnWidth: "20%"
              barWidth: "20%",
            },
          },
        },
      };
      this.seriesMissingCategories = [
        // { name: "score_avg", data: score_avgs },
        { name: "count", data: counts },
      ];
    },

    setChartMissingKeywordsData(competitorsData, urlData) {
      const competitorsKeywordsAll = [];
      competitorsData.forEach((competitorData) => {
        const competitorKeywords = (({ keywords }) => ({ keywords }))(
          competitorData
        ).keywords;
        competitorKeywords.forEach((keyword) => {
          competitorsKeywordsAll.push(keyword);
        });
      });
      const competitorsKeywordsFiltered = [];

      competitorsKeywordsAll.forEach((keyword) => {
        const keywordData = {
          keyword: keyword.text,
          relevance_sum: keyword.relevance,
          count: +keyword.count,
          serps: [keyword.url],
        };
        competitorsKeywordsAll.forEach((keyword2) => {
          if (keyword.text == keyword2.text && keyword.url != keyword2.url) {
            keywordData.relevance_sum += +keyword2.relevance;
            // keywordData.count++;
            keywordData.count += +keyword2.count;
            keywordData.serps.push(keyword2.url);
          }
        });
        const data = {
          keyword: keywordData.keyword,
          count: keywordData.count,
          relevance_avg: Number(
            +keywordData.relevance_sum / keywordData.serps.length
          ).toFixed(3),
          serps: keywordData.serps,
        };
        competitorsKeywordsFiltered.push(data);
      });
      const competitorsKeywords = competitorsKeywordsFiltered
        .filter(
          (v, i, a) =>
            a.findIndex((t) =>
              ["keyword", "count", "relevance_avg"].every((k) => t[k] === v[k])
            ) === i
        )
        .sort((a, b) =>
          a.relevance_avg < b.relevance_avg
            ? 1
            : b.relevance_avg < a.relevance_avg
            ? -1
            : 0
        )
        .sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0));
      const chartOptions = [];
      const counts = [];
      const relevance_avgs = [];
      for (let i = 0; i < competitorsKeywords.slice(0, 20).length; i++) {
        const item = competitorsKeywords[i];
        chartOptions.push(item.keyword);
        if (urlData.keywords.some((e) => e.text === item.keyword)) {
          counts.push({
            x: item.keyword,
            y: item.count,
            // fillColor: "#5555FF",
          });
          relevance_avgs.push({
            x: item.keyword,
            y: item.relevance_avg,
            // fillColor: "#0000FF",
          });
        } else {
          counts.push({
            x: item.keyword,
            y: item.count,
            fillColor: "#FF5555",
          });
          relevance_avgs.push({
            x: item.keyword,
            y: item.relevance_avg,
            fillColor: "#FF0000",
          });
        }
      }
      this.chartMissingKeywordsOptions = {
        ...this.chartMissingKeywordsOptions,
        ...{
          xaxis: {
            categories: chartOptions,
            labels: {
              show: true,
              style: {
                colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              fontSize: "10px",
            },
          },
          plotOptions: {
            bar: {
              barHeight: "100%",
              borderRadius: 4,
              horizontal: true,
            },
          },
        },
      };
      this.seriesMissingKeywords = [
        { name: "count", data: counts },
        { name: "relevance_avg", data: relevance_avgs },
      ];
    },

    setChartMissingConceptsData(competitorsData, urlData) {
      const competitorsConceptsAll = [];
      competitorsData.forEach((competitorData) => {
        const competitorConcepts = (({ concepts }) => ({ concepts }))(
          competitorData
        ).concepts;
        competitorConcepts.forEach((concept) => {
          competitorsConceptsAll.push(concept);
        });
      });
      const competitorsConceptsFiltered = [];
      competitorsConceptsAll.forEach((concept) => {
        const conceptData = {
          concept: concept.text,
          relevance_sum: concept.relevance,
          // rank_group_sum: concept.rank_group,
          count: 1,
          serps: [concept.url + ";" + concept.rank_group],
        };
        competitorsConceptsAll.forEach((concept2) => {
          if (concept.text == concept2.text && concept.url != concept2.url) {
            conceptData.relevance_sum += +concept2.relevance;
            conceptData.count++;
            conceptData.serps.push(concept2.url + ";" + concept2.rank_group);
          }
        });
        const data = {
          concept: conceptData.concept,
          count: conceptData.count,
          relevance_avg: Number(
            +conceptData.relevance_sum / +conceptData.count
          ).toFixed(3),
          serps: conceptData.serps,
        };
        competitorsConceptsFiltered.push(data);
      });
      const competitorsConcepts = competitorsConceptsFiltered
        .filter(
          (v, i, a) =>
            a.findIndex((t) =>
              ["concept", "count", "relevance_avg"].every((k) => t[k] === v[k])
            ) === i
        )
        .sort((a, b) =>
          a.relevance_avg < b.relevance_avg
            ? 1
            : b.relevance_avg < a.relevance_avg
            ? -1
            : 0
        )
        .sort((a, b) => (a.count < b.count ? 1 : b.count < a.count ? -1 : 0));

      const chartOptions = [];
      const counts = [];
      const relevance_avgs = [];
      for (let i = 0; i < competitorsConcepts.slice(0, 20).length; i++) {
        const item = competitorsConcepts[i];
        chartOptions.push(item.concept);
        if (urlData.concepts.some((e) => e.concept === item.concept)) {
          const ownConceptData = urlData.concepts.filter((obj) => {
            return obj.concept === item.concept;
          })[0];
          // console.log(urlData, 999);
          counts.push({
            x: item.concept,
            y: item.count,
            fillColor: "#0000FF",
          });
          relevance_avgs.push({
            x: item.concept,
            y: -item.relevance_avg,
            fillColor: "#0000FF",
            // forecastDataPoints: {
            //   count: 0,
            //   fillOpacity: 0.5,
            //   strokeWidth: 20,
            //   dashArray: 4,
            // },

            // fillColor: "#EB8C87",
            // strokeColor: "#C23829",

            goals: [
              {
                name: "relevance",
                value: ownConceptData.relevance,
                strokeWidth: 5,
                strokeHeight: 10,
                strokeColor: "#00FF00",
              },
            ],
          });
        } else {
          counts.push({
            x: item.concept,
            y: item.count,
            fillColor: "#FF0000",
          });
          relevance_avgs.push({
            x: item.concept,
            y: -item.relevance_avg,
            fillColor: "#FF0000",
            // goals: [
            //   {
            //     name: "relevance to our url",
            //     value: 1,
            //     strokeWidth: 5,
            //     strokeHeight: 10,
            //     strokeColor: "#00FF00",
            //   },
            // ],
          });
        }
      }
      // console.log(relevance_avgs, 123);
      relevance_avgs.map((value) => {
        return (value.y = value.y * counts[0].y);
      });

      // console.log(relevance_avgs, 456);
      // console.log(counts, 123);

      // this.chartMissingConceptsOptions.yaxis.min = -counts[0].y;
      // this.chartMissingConceptsOptions.yaxis.max = counts[0].y;
      this.chartMissingConceptsOptions = {
        ...this.chartMissingConceptsOptions,
        ...{
          yaxis: {
            min: -counts[0].y,
            max: counts[0].y,
            title: {
              text: "",
            },
          },
          xaxis: {
            categories: chartOptions,
            title: {
              text: "",
            },
            labels: {
              show: true,
              // show: false,
              style: {
                // colors: ["#a8a8a8"],
                fontSize: "10px",
                fontFamily: "Arial",
              },
              formatter: function (val) {
                if (val < 0) {
                  return Math.abs(Math.round(val) / counts[0].y).toFixed(1);
                }
                return Math.abs(Math.round(val)).toFixed(0);
              },
            },
          },

          dataLabels: {
            enabled: true,
            formatter: function (val) {
              if (val < 0) {
                return Math.abs(val / counts[0].y).toFixed(3);
              }
              return Math.abs(val);
            },
            style: {
              fontSize: "10px",
            },
          },

          legend: {
            show: true,
            showForSingleSeries: true,
            customLegendItems: ["Missing", "Existing"],
            markers: {
              fillColors: ["#FF0000", "#0000FF"],
            },
          },

          tooltip: {
            shared: false,
            x: {
              formatter: function (val) {
                // console.log(val, 123);

                return val + "";
              },
            },
            y: {
              formatter: function (val) {
                if (val < 0) {
                  return Math.abs(val / counts[0].y).toFixed(3);
                }
                // return Math.abs(val).toFixed(0);
                return val;
              },
            },
          },
        },
      };

      this.seriesMissingConcepts = [
        { name: "relevance_avg", data: relevance_avgs },
        // { name: "relevance_avg", data: relevance_avgsScaled },
        { name: "count", data: counts },
      ];
    },

    async setCompetitors(data) {
      this.dataforseoItems = data.output.competitorsData;
      this.filterInput.value = data.serp_top_n;
      this.keyword.text = data.keyword;
      this.keyword.url = data.url;
      this.keyword.device = data.device;
      this.keyword.excludeOwnUrl = data.excludeOwnUrl;
      this.keyword.language_code = data.language_code;
      this.keyword.location.code = data.location_code;

      this.setChartMissingConceptsData(
        data.output.competitorsData,
        data.output.urlData
      );
      this.setChartMissingKeywordsData(
        data.output.competitorsData,
        data.output.urlData
      );
      this.setChartMissingCategoriesData(
        data.output.competitorsData,
        data.output.urlData
      );
      this.setChartMissingEntitiesData(
        data.output.competitorsData,
        data.output.urlData
      );

      return;
    },
  },
};
</script>